require('./bootstrap');

$(function(){
    if ($('#dataTable').length > 0) {
        $('#dataTable').DataTable({
            "pageLength": 25
        });
    }
    
    if ($('#dataTableNoPaging').length > 0) {
        $('#dataTableNoPaging').DataTable({
            paging: false
        });
    }
})